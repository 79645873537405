// import React, { useContext, useEffect, useState } from 'react';
// import axios from 'axios';
// import App from './App';
// import { ThreeDots } from 'react-loader-spinner';
// import { AppContext } from './context/AppContext';
// import config from './config';

// const Auth = () => {
//   const { dispatch } = useContext(AppContext);
//   const [loading, setLoading] = useState(true);
//   const [referralCode, setReferralCode] = useState(null);
//   const [username, setUsername] = useState(null);

//   // }, []);
//   useEffect(() => {
//     // Check for referral code in URL (Telegram WebApp might pass it as `start`)
//     const params = new URLSearchParams(window.location.search);
//     const referral = params.get('referral') || params.get('start');
//     if (referral) {
//       setReferralCode(referral);
//       console.log('Referral code:', referral);
//     }
//   }, []);

//   useEffect(() => {
//     // Check if Telegram WebApp is available
//     const tgAvailable = window.Telegram && window.Telegram.WebApp;


//     if (tgAvailable) {
//       const tg = window.Telegram.WebApp;
//       tg.ready();
      
//       // Retrieve the username from Telegram WebApp
//       const telegramUsername = tg.initDataUnsafe.user?.username || tg.initDataUnsafe.user?.first_name;
//       setUsername(telegramUsername);
//       console.log(username);
//     } else {
//       console.error("Telegram WebApp API is not available");
//       setLoading(false); // Stop loading if WebApp API is unavailable
//     }
//   }, []);

//   useEffect(() => {
//     // if (!username) return; // Wait for the username to be set
//     // setUsername('testuser');
//     const apiUrl = `${config.apiUrl}/auth`;

//     const fetchUserData = async () => {
//       try {
//         const response = await axios.post(apiUrl, { username, referralCode });
//         const userData = response.data.user;

//         // Calculate bar refill
//         const { current_timestamp, last_activity_timestamp, bullton_bar_speed, bullton_bar_limit, bullton_bar_current } = userData;
//         const elapsedTime = current_timestamp - last_activity_timestamp;
//         const refillSpeed = parseInt(bullton_bar_speed, 10);

//         // Calculate refilled amount and update bar current
//         const refilledAmount = Math.floor(elapsedTime / refillSpeed);
//         const updatedBarCurrent = Math.min(bullton_bar_current + refilledAmount, bullton_bar_limit);

//         // Update user data in context with calculated bar current
//         dispatch({
//           type: 'SET_USER_DATA',
//           payload: { ...response.data, user: { ...userData, bullton_bar_current: updatedBarCurrent } }
//         });
//         dispatch({ type: 'SET_AUTHENTICATED', payload: true });
//       } catch (error) {
//         console.error('Failed to fetch user data:', error);
//         dispatch({ type: 'SET_AUTHENTICATED', payload: false });
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserData();
//   }, [dispatch, referralCode, username]);

//   if (loading) {
//     return (
//       <div className="loader-container">
//         <ThreeDots color="#00BFFF" height={80} width={80} />
//       </div>
//     );
//   }

//   return <App />;
// };

// export default Auth;


// src/components/Auth.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import App from './App';
import { ThreeDots } from 'react-loader-spinner';
import { AppContext } from './context/AppContext';
import config from './config';

const Auth = () => {
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [referralCode, setReferralCode] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    // Check if Telegram WebApp is available
    const tgAvailable = window.Telegram && window.Telegram.WebApp;

    if (tgAvailable) {
      const tg = window.Telegram.WebApp;
      tg.ready();

      // Retrieve the username from Telegram WebApp
      const telegramUsername = tg.initDataUnsafe.user?.username || tg.initDataUnsafe.user?.first_name;
      setUsername(telegramUsername);

      // Retrieve the referral code directly from `start_param` in initDataUnsafe
      const referral = tg.initDataUnsafe.start_param;
      if (referral) {
        setReferralCode(referral);
        console.log('Referral code:', referral);
      } else {
        console.log("No referral code found in start_param.");
      }
    } else {
      console.error("Telegram WebApp API is not available");
      setLoading(false); // Stop loading if WebApp API is unavailable
    }
  }, []);

  useEffect(() => {
    if (!username) return; // Ensure username is set before fetching data

    const apiUrl = `${config.apiUrl}/auth`;

    const fetchUserData = async () => {
      try {
        const response = await axios.post(apiUrl, { username, referralCode });
        const userData = response.data.user;

        // Calculate bar refill
        const { current_timestamp, last_activity_timestamp, bullton_bar_speed, bullton_bar_limit, bullton_bar_current } = userData;
        const elapsedTime = current_timestamp - last_activity_timestamp;
        const refillSpeed = parseInt(bullton_bar_speed, 10);

        // Calculate refilled amount and update bar current
        const refilledAmount = Math.floor(elapsedTime / refillSpeed);
        const updatedBarCurrent = Math.min(bullton_bar_current + refilledAmount, bullton_bar_limit);

        // Update user data in context with calculated bar current
        dispatch({
          type: 'SET_USER_DATA',
          payload: { ...response.data, user: { ...userData, bullton_bar_current: updatedBarCurrent } }
        });
        dispatch({ type: 'SET_AUTHENTICATED', payload: true });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        dispatch({ type: 'SET_AUTHENTICATED', payload: false });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [dispatch, referralCode, username]);

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return <App />;
};

export default Auth;
