// import React, { useState, useContext } from 'react';
// import largeCoin from '../assets/large-coin.png';
// import '../css/LargeCoin.css'; // Custom CSS for the animations
// import { AppContext } from '../context/AppContext'; // Import the context
// import axios from 'axios';
// import config from '../config';

// const LargeCoin = () => {
//   const [plusOnes, setPlusOnes] = useState([]);
//   const [isShaking, setIsShaking] = useState(false);
//   const { state, dispatch } = useContext(AppContext);

//   const handleClick = () => {
//     // Add a new +1 text to the array
//     const newPlusOne = { id: Date.now() };
//     setPlusOnes(prev => [...prev, newPlusOne]);

//     // Remove the +1 text after the animation duration (600ms)
//     setTimeout(() => {
//       setPlusOnes(prev => prev.filter(item => item.id !== newPlusOne.id));
//     }, 600);

//     // Trigger the shake animation
//     setIsShaking(true);
//     setTimeout(() => {
//       setIsShaking(false); // Remove the shake animation class after 400ms
//     }, 400);

//     // reduce the bullton bar current value
//     var current = state.userData?.user?.bullton_bar_current;
//     var limit = state.userData?.user?.bullton_bar_limit;
//     const dec = state.userData?.user?.bullton_value_per_tap;
//     if (current >= 0 && current <= limit) {
//       dispatch({ type: 'UPDATE_BULLTON_BAR', payload: dec });
//     }

//     // check if balance is exceeding current level max limit if yes then update the level
//     if (state.userData?.user?.balance > state.userData?.user?.coin_range?.maximum_range) {
//       // call a function to get the new level
//       updateLevel(state.userData?.user?.username, state.userData?.user?.balance).then(data => {
//         console.log('Level updated:', data);
//         dispatch({ type: 'Update_Level_Limits', payload: data.record });
//       }).catch(error => {
//         console.error('Failed to update level:', error);
//       });
      
//     }
//     // Calculate the increment value and dispatch the action
//     dispatch({ type: 'UPDATE_BALANCE', payload: dec });
//   };

//   // make a function to be called to updte the level
//   const updateLevel = async (username, balance) => {
//     try {
//       const response = await axios.post(`${config.apiUrl}/update-balance`, { username, balance });
//       return response.data;
//     } catch (error) {
//       console.error('Error updating balance:', error);
//       throw error;
//     }
//   };

//   return (
//     <div className="large-coin-container text-center mb-4">
//       <div className={`coin-large ${isShaking ? 'animate-shake' : ''}`} onClick={handleClick}>
//         <img src={largeCoin} alt="Large Coin" className="img-fluid" />
//         {plusOnes.map(item => (
//           <div key={item.id} className="plus-one">+{state.userData?.user?.bullton_value_per_tap}</div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default LargeCoin;


// import React, { useState, useContext } from 'react';
// import largeCoin from '../assets/large-coin.png';
// import '../css/LargeCoin.css'; // Custom CSS for the animations
// import { AppContext } from '../context/AppContext'; // Import the context
// import axios from 'axios';
// import config from '../config';

// const LargeCoin = () => {
//   const [plusOnes, setPlusOnes] = useState([]);
//   const [isShaking, setIsShaking] = useState(false);
//   const { state, dispatch } = useContext(AppContext);

//   const handleTouch = (e) => {
//     // Prevent default behavior for touch events
//     e.preventDefault();
  
//     // Ensure the event has valid touches before proceeding
//     if (e.touches && e.touches.length > 0) {
//       // Loop through each touch point
//       const touches = e.touches;
//       for (let i = 0; i < touches.length; i++) {
//         const touch = touches[i];
//         const rect = e.target.getBoundingClientRect();
//         const clickX = touch.clientX - rect.left;
//         const clickY = touch.clientY - rect.top;
  
//         // Add a new +1 text to the array for each touch
//         const newPlusOne = { id: Date.now() + i, x: clickX, y: clickY };
//         setPlusOnes(prev => [...prev, newPlusOne]);
  
//         // Remove the +1 text after the animation duration (600ms)
//         setTimeout(() => {
//           setPlusOnes(prev => prev.filter(item => item.id !== newPlusOne.id));
//         }, 600);
  
//         // Update bullton_bar_current
//         const current = state.userData?.user?.bullton_bar_current;
//         const limit = state.userData?.user?.bullton_bar_limit;
//         const dec = state.userData?.user?.bullton_value_per_tap;
  
//         if (current >= 0 && current <= limit) {
//           dispatch({ type: 'UPDATE_BULLTON_BAR', payload: dec });
//         }
  
//         // Check if balance is exceeding current level max limit and update level
//         if (state.userData?.user?.balance > state.userData?.user?.coin_range?.maximum_range) {
//           updateLevel(state.userData?.user?.username, state.userData?.user?.balance)
//             .then(data => {
//               console.log('Level updated:', data);
//               dispatch({ type: 'Update_Level_Limits', payload: data.record });
//             })
//             .catch(error => {
//               console.error('Failed to update level:', error);
//             });
//         }

        
  
//         // Update balance
//         dispatch({ type: 'UPDATE_BALANCE', payload: dec });
//       }
  
//       // Trigger the shake animation for each tap
//       setIsShaking(true);
//       setTimeout(() => {
//         setIsShaking(false); // Remove the shake animation class after 400ms
//       }, 400);
//     }
//   };
  

//   const updateLevel = async (username, balance) => {
//     try {
//       const response = await axios.post(`${config.apiUrl}/update-balance`, { username, balance });
//       return response.data;
//     } catch (error) {
//       console.error('Error updating balance:', error);
//       throw error;
//     }
//   };

//   return (
//     <div className="large-coin-container text-center mb-4">
//       <div
//         className={`coin-large ${isShaking ? 'animate-shake' : ''}`}
//         onTouchStart={handleTouch} // Listen for touch events
//         onPointerDown={handleTouch} // Support pointer events (multi-touch on desktops and tablets)
//       >
//         <img src={largeCoin} alt="Large Coin" className="img-fluid" />
//         {plusOnes.map(item => (
//           <div
//             key={item.id}
//             className="plus-one"
//             style={{ left: `${item.x}px`, top: `${item.y}px` }}
//           >
//             +{state.userData?.user?.bullton_value_per_tap}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default LargeCoin;



import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import largeCoin from '../assets/large-coin.png';
import '../css/LargeCoin.css';
import { AppContext } from '../context/AppContext';
import axios from 'axios';
import config from '../config';

const LargeCoin = () => {
  const [plusOnes, setPlusOnes] = useState([]);
  const [isShaking, setIsShaking] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const debounceTimeout = useRef(null); // For controlling API calls at the end of interaction

  const updateBalanceAPI = useCallback(async () => {
    const currentBalance = state.userData?.user?.balance || 0;
    const username = state.userData?.user?.username;
    const bulltonBarCurrent = state.userData?.user?.bullton_bar_current;

    try {
      const response = await axios.post(`${config.apiUrl}/update-balance`, { username, balance: currentBalance, bulltonBarCurrent : bulltonBarCurrent });
      console.log('Balance updated:', response);
    } catch (error) {
      console.error('Failed to update balance:', error);
    }
  }, [state.userData]);

  useEffect(() => {
    return () => clearTimeout(debounceTimeout.current); // Cleanup on component unmount
  }, []);

  const handleTouch = (e) => {
    e.preventDefault();
    if (e.touches && e.touches.length > 0) {
      const touches = e.touches;
      for (let i = 0; i < touches.length; i++) {
        const touch = touches[i];
        const rect = e.target.getBoundingClientRect();
        const clickX = touch.clientX - rect.left;
        const clickY = touch.clientY - rect.top;

        const newPlusOne = { id: Date.now() + i, x: clickX, y: clickY };
        setPlusOnes((prev) => [...prev, newPlusOne]);
        setTimeout(() => {
          setPlusOnes((prev) => prev.filter((item) => item.id !== newPlusOne.id));
        }, 600);

        const current = state.userData?.user?.bullton_bar_current;
        const limit = state.userData?.user?.bullton_bar_limit;
        const dec = state.userData?.user?.bullton_value_per_tap;

        if (current > 0) {
          // Decrement bullton bar if not yet zero
          dispatch({ type: 'UPDATE_BULLTON_BAR', payload: dec });
          // Update balance
          dispatch({ type: 'UPDATE_BALANCE', payload: dec });
        } else {
          // Prevent further decrement when limit is reached and trigger the balance update API
          clearTimeout(debounceTimeout.current);
          debounceTimeout.current = setTimeout(updateBalanceAPI, 1000);
          break;
        }
      }

      // Shake animation
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 400);

      // Reset debounce timer for API call to wait 1 second after last interaction
      clearTimeout(debounceTimeout.current);
      debounceTimeout.current = setTimeout(updateBalanceAPI, 1000);
    }
  };

  return (
    <div className="large-coin-container text-center mb-4">
      <div
        className={`coin-large ${isShaking ? 'animate-shake' : ''}`}
        onTouchStart={handleTouch}
        onPointerDown={handleTouch}
      >
        <img src={largeCoin} alt="Large Coin" className="img-fluid" />
        {plusOnes.map((item) => (
          <div
            key={item.id}
            className="plus-one"
            style={{ left: `${item.x}px`, top: `${item.y}px` }}
          >
            +{state.userData?.user?.bullton_value_per_tap}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LargeCoin;
