// // src/components/InviteLink.js
// import React from 'react';
// import '../css/InviteLink.css'; // Custom CSS for InviteLink

// const InviteLink = ({ code }) => {
//   // get react url without further routes
//   const url = window.location.origin;
//   const link = `${url}?referral=${code}`;

//   const handleCopy = async () => {
//     try {
//       if (navigator.clipboard && window.isSecureContext) {
//         // Navigator clipboard API method
//         await navigator.clipboard.writeText(link);
//         alert('Invite link copied to clipboard!');
//       } else {
//         // Fallback method
//         const textArea = document.createElement("textarea");
//         textArea.value = link;
//         // Ensure the textarea is not visible on the screen
//         textArea.style.position = "fixed";
//         textArea.style.top = "-999999px";
//         document.body.appendChild(textArea);
//         textArea.focus();
//         textArea.select();
//         document.execCommand('copy');
//         document.body.removeChild(textArea);
//         alert('Invite link copied to clipboard!');
//       }
//     } catch (err) {
//       console.error('Failed to copy: ', err);
//       alert('Failed to copy invite link.');
//     }
//   };

//   return (
//     <div className="invite-link-container">
//       <p className="invite-link-text">My invite link:</p>
//       <div className="invite-link-box">
//         <span className="invite-link">{link}</span>
//         <button className="copy-button" onClick={handleCopy}>Copy</button>
//       </div>
//     </div>
//   );
// };

// export default InviteLink;


// src/components/InviteLink.js
import React from 'react';
import '../css/InviteLink.css'; // Custom CSS for InviteLink

const InviteLink = ({ code }) => {
  // Generate the Telegram bot invite link with a referral code
  const botUsername = "bultonbot";  // Replace with your actual bot's username
  const link = `https://t.me/${botUsername}?startapp=${code}`;

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Navigator clipboard API if available
        await navigator.clipboard.writeText(link);
        alert('Invite link copied to clipboard!');
      } else {
        // Fallback method for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = link;
        // Make the textarea not visible on the screen
        textArea.style.position = "fixed";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert('Invite link copied to clipboard!');
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
      alert('Failed to copy invite link.');
    }
  };

  return (
    <div className="invite-link-container">
      <p className="invite-link-text">My invite link:</p>
      <div className="invite-link-box">
        <span className="invite-link">{link}</span>
        <button className="copy-button" onClick={handleCopy}>Copy</button>
      </div>
    </div>
  );
};

export default InviteLink;
